import { Box, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { theme } from "../../theme";
import { NewtCloudLogo } from "../../img/NewtCloudLogo";
import { Link as RouterLink } from "react-router-dom";

interface PlainContainerProps {
  title?: string;
  children: React.ReactElement;
}

export default function PlainContainer(props: PlainContainerProps) {
  const { children, title } = props;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100dvh"
    >
      <Stack
        direction="column"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <div className="chakra-image css-16duawu">
          <RouterLink to="/">
            <Box sx={{ height: "75px" }}>
              <NewtCloudLogo />
            </Box>
          </RouterLink>
        </div>
        {title && <Typography variant="h3">{title}</Typography>}
        <Paper
          sx={{
            width: { xs: 340, sm: 400, md: 500, lg: 500 },
            padding: theme.spacing(2),
          }}
        >
          {children}
        </Paper>
      </Stack>
    </Box>
  );
}
