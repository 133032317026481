import { Alert } from "@mui/material";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext";
import BannerContext from "../../context/BannerContext";

interface LearnHowAlertProps {}

const TRIAL_THREE_DAYS = 7;

export default function LearnHowAlert(props: LearnHowAlertProps) {
  const {
    actions: { setIsThreeClosed },
    data: { isThreeClosed },
  } = useContext(BannerContext);

  const [bannerTime, setBannerTime] = useState(0);

  const {
    data: { account },
  } = useContext(AuthContext);

  useEffect(() => {
    const now = dayjs().utc();
    const created_on = dayjs.utc(account?.created_on);
    setBannerTime(Math.ceil(created_on.diff(now, "hour") / 24));
  }, [account?.created_on]);

  if (!isThreeClosed && bannerTime < TRIAL_THREE_DAYS && account?.verified)
    return (
      <Alert
        onClick={() =>
          window.open("https://youtu.be/mt4djJNK524?si=1rnTSrrgcCZJKl8Z", "_blank")
        }
        severity="info"
        sx={{ cursor: "pointer" }}
        onClose={(e) => {
          e.stopPropagation();
          setIsThreeClosed(true);
        }}
      >
        Learn how to use Nucleus <u>here</u>.
      </Alert>
    );
}
