import { Stack } from "@mui/material";
import { useContext } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { IHomeFormInput } from "../../interfaces/IHomeFormInput";
import { ModelCardLayout } from "../ModelCardLayout";
import { AccessLevel } from "../../interfaces/IGetUserResult";
import AuthContext from "../../context/AuthContext";

export interface ModelCardSectionProps {}

export function ModelCardSection(props: ModelCardSectionProps) {
  const {
    data: { account },
  } = useContext(AuthContext);

  const { control } = useFormContext<IHomeFormInput>();
  useFieldArray({
    control,
    name: "cards",
  });

  const cardsDisabled = !(
    account?.access_level === AccessLevel.PRO ||
    (account?.access_level === AccessLevel.FREE &&
      process.env.REACT_APP_STRIPE_ENABLE !== "true")
  );

  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
      >
        <ModelCardLayout disabled={cardsDisabled} />
      </Stack>
    </>
  );
}
