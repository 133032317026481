import { Alert } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { AccessLevel } from "../../interfaces/IGetUserResult";

interface SuspendAlertProps {}

export default function SuspendAlert(props: SuspendAlertProps) {
  const navigate = useNavigate();

  const {
    data: { account },
  } = useContext(AuthContext);

  if (account?.access_level === AccessLevel.SUSPENDED)
    return (
      <Alert
        onClick={() => navigate("/store")}
        severity="error"
        sx={{ cursor: "pointer" }}
      >
        This account has been suspended. <u>Click here</u> to reactivate this
        account.
      </Alert>
    );
}
