import React, { useCallback, useContext, useEffect, useState } from "react";
import { default as ApiService } from "../context/ApiServices";
import { CardItem } from "../interfaces/CardItem";
import { ICityResult } from "../interfaces/ICityResult";
import { ICreateStripePriceResult } from "../interfaces/IGetUserResult";
import { IVoiceResult } from "../interfaces/IVoiceResult";
import AuthContext from "./AuthContext";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export const PRO_MONTH_PRICE_NAME = "PRO_MONTH";
export const PRO_YEAR_PRICE_NAME = "PRO_YEAR";

const ApiContext = React.createContext<{
  actions: {
    getVoices: () => Promise<IVoiceResult[]>;
    getCities: () => Promise<ICityResult[]>;

    createStoreSession: (
      promo_code?: string,
      return_url?: string,
      lookup_key?: string
    ) => Promise<string>;
    createStorePortalSession: (_: string) => Promise<string>;
    createStorePriceTableSession: () => Promise<ICreateStripePriceResult>;
    createStoreUpgradeSession: (_: string) => Promise<string>;
    createTicket: (name: string, subject: string, body: string) => Promise<any>;

    setSuccess: (_: string | null | undefined) => void;
    setError: (_: string | null | undefined) => void;
    setWarning: (_: string | null | undefined) => void;
  };
  data: {
    defaultCards: CardItem[];
    voices: IVoiceResult[];
    cities: ICityResult[];

    success: string | null | undefined;
    error: string | null | undefined;
    warning: string | null | undefined;
  };
}>({
  actions: {
    getVoices: async () => [],
    getCities: async () => [],
    createStoreSession: async () => "",
    createStorePortalSession: async () => "",
    createStorePriceTableSession: async () => ({ price_id: "", secret: "" }),
    createStoreUpgradeSession: async () => "",
    createTicket: async () => {},
    setSuccess: () => {},
    setError: () => {},
    setWarning: () => {},
  },
  data: {
    defaultCards: [],
    voices: [],
    cities: [],
    success: undefined,
    error: undefined,
    warning: undefined,
  },
});

interface ApiContextProviderProps {
  children: React.ReactNode;
}

export function ApiContextProvider(
  props: ApiContextProviderProps
): React.ReactElement {
  const { children } = props;
  const {
    data: { authToken },
    actions: { isLoggedIn },
  } = useContext(AuthContext);

  const [voices, setVoices] = useState<IVoiceResult[]>([]);
  const [cities, setCities] = useState<ICityResult[]>([]);

  const [defaultCards, setDefaultCards] = useState<CardItem[]>([]);

  const [warning, setWarning] = useState<string | null | undefined>();
  useEffect(() => {
    if (warning) setTimeout(() => setWarning(null), 10 * 1000);
  }, [warning, setWarning]);

  const [error, setError] = useState<string | null | undefined>(null);
  const [success, setSuccess] = useState<string | null | undefined>(null);

  const getVoices = useCallback(() => {
    return new Promise<IVoiceResult[]>(async (resolve, reject) => {
      try {
        const response = await ApiService.api.get(`voices`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const voices: IVoiceResult[] = response.data;
        if (voices) {
          const sorted = voices.sort((a, b) => {
            if (a.is_premium === b.is_premium) return a.name > b.name ? 1 : -1;
            return a.is_premium && !b.is_premium ? 1 : -1;
          });
          setVoices(sorted);
          resolve(sorted);
        } else {
          resolve([]);
        }
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  const getCities = useCallback(() => {
    return new Promise<ICityResult[]>(async (resolve, reject) => {
      try {
        const response = await ApiService.api.get(`cities`, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        const cities: ICityResult[] = response.data;
        if (cities) {
          const sorted = cities.sort((a, b) => {
            if (a.region === b.region) return a.city > b.city ? 1 : -1;
            return a.region > b.region ? 1 : -1;
          });

          resolve(sorted);
        } else {
          resolve([]);
        }
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  const getDefaultCards = useCallback(() => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const response = await ApiService.api.get(`default_context`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setDefaultCards(response.data.cards);
        resolve(response.data);
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  const createStoreSession = useCallback(
    (promo_code?: string, return_url?: string, lookup_key?: string) => {
      return new Promise<any>(async (resolve, reject) => {
        if (!isLoggedIn()) {
          reject("You are not logged in. Please log in and try again");
        }
        try {
          const token = authToken;
          const response = await ApiService.api.post(
            `stripe/create`,
            { promo_code, return_url, lookup_key },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token?.access_token}`,
              },
            }
          );

          resolve(response.data);
        } catch (e: any) {
          reject(e);
        }
      });
    },
    [authToken, isLoggedIn]
  );

  const createStorePortalSession = useCallback(
    (return_url?: string) => {
      return new Promise<any>(async (resolve, reject) => {
        if (!isLoggedIn()) {
          reject("You are not logged in. Please log in and try again");
        }
        try {
          const token = authToken;
          const response = await ApiService.api.post(
            `stripe/portal/create`,
            { return_url },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token?.access_token}`,
              },
            }
          );

          resolve(response.data);
        } catch (e: any) {
          reject(e);
        }
      });
    },
    [authToken, isLoggedIn]
  );

  const createStorePriceTableSession = useCallback(() => {
    return new Promise<any>(async (resolve, reject) => {
      if (!isLoggedIn()) {
        reject("You are not logged in. Please log in and try again");
      }
      try {
        const token = authToken;
        const response = await ApiService.api.post(
          `stripe/pricetable/create`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token?.access_token}`,
            },
          }
        );

        resolve(response.data);
      } catch (e: any) {
        reject(e);
      }
    });
  }, [authToken, isLoggedIn]);

  const createStoreUpgradeSession = useCallback(
    (promo_code?: string, return_url?: string, lookup_key?: string) => {
      return new Promise<any>(async (resolve, reject) => {
        if (!isLoggedIn()) {
          reject("You are not logged in. Please log in and try again");
        }
        try {
          const token = authToken;
          const response = await ApiService.api.post(
            `stripe/upgrade/create`,
            { promo_code, return_url, lookup_key },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token?.access_token}`,
              },
            }
          );

          resolve(response.data);
        } catch (e: any) {
          reject(e);
        }
      });
    },
    [authToken, isLoggedIn]
  );

  const createTicket = useCallback(
    (name: string, subject: string, body: string) => {
      return new Promise<any>(async (resolve, reject) => {
        if (!isLoggedIn()) {
          reject("You are not logged in. Please log in and try again");
        }
        try {
          const token = authToken;
          const response = await ApiService.api.post(
            `ticket`,
            { name, subject, body },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token?.access_token}`,
              },
            }
          );

          resolve(response.data);
        } catch (e: any) {
          reject(e);
        }
      });
    },
    [authToken, isLoggedIn]
  );

  useEffect(() => {
    async function fetchData() {
      const v = await getVoices();

      setVoices(v);
    }
    fetchData();
  }, [getVoices]);

  useEffect(() => {
    async function fetchData() {
      setCities(await getCities());
    }
    fetchData();
  }, [getCities]);

  useEffect(() => {
    if (isLoggedIn()) getDefaultCards();
  }, [authToken, getDefaultCards, isLoggedIn]);

  return (
    <ApiContext.Provider
      value={{
        actions: {
          getVoices,
          getCities,
          createStoreSession,
          createStorePortalSession,
          createStorePriceTableSession,
          createStoreUpgradeSession,
          createTicket,
          setSuccess,
          setError,
          setWarning,
        },
        data: {
          defaultCards,
          voices,
          cities,
          success,
          error,
          warning,
        },
      }}
    >
      {children}
    </ApiContext.Provider>
  );
}
export default ApiContext;
