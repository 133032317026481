import { Box, CircularProgress } from "@mui/material";
import { useContext, useEffect } from "react";
import ApiContext from "../context/ApiContext";
import AuthContext from "../context/AuthContext";
import { useNavigateToTop } from "../utils/utils";

const properties = {
  display: "grid",
  placeItems: "center",
  height: "100vh",
};
export default function StorePortalView() {
  const navigateToTop = useNavigateToTop();

  const {
    data: { authToken },
    actions: { isLoggedIn },
  } = useContext(AuthContext);
  const {
    actions: { createStorePortalSession },
  } = useContext(ApiContext);

  const loadStoreUrl = async () => {
    try {
      const url = await createStorePortalSession(
        `${window.location.origin}/?sessionid={CHECKOUT_SESSION_ID}`
      );
      window.open(url, "_self");
    } catch (e) {
      navigateToTop("/");
    }
  };

  useEffect(() => {
    if (isLoggedIn()) loadStoreUrl();
  }, [authToken]);

  return (
    <Box sx={properties}>
      <CircularProgress size={50} />
    </Box>
  );
}
