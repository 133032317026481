import {
  Box,
  Dialog,
  Grid,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { theme } from "../../theme";
import React from "react";
import { NucleusButton } from "../NucleusButton";
import {
  PRO_YEAR_PRICE_NAME,
  PRO_MONTH_PRICE_NAME,
} from "../../context/ApiContext";

export interface ProAlertDialogProps {
  open: boolean;
  onClose: (reason?: string) => void;
  onOpenStore: (lookup_key: string) => void;
}

export function ProAlertDialog(props: ProAlertDialogProps) {
  const { open, onClose, onOpenStore } = props;
  const [monthYear, setMonthYear] = useState(PRO_YEAR_PRICE_NAME);
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newMonthYear: string
  ) => {
    setMonthYear(newMonthYear);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      PaperProps={{
        sx: { border: "none" },
      }}
      maxWidth={false}
    >
      <Paper
        sx={{
          width: { xs: 320, sm: 500, md: 700, lg: 700 },
          padding: theme.spacing(2),
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6} order={{ xs: 2, sm: 1 }}>
            <Stack
              direction="column"
              spacing={0}
              justifyContent="center"
              alignItems="stretch"
            >
              <article>
                <Typography variant="h3">Starter</Typography>
                <Box sx={{ minHeight: "55px" }}>
                  <Typography>
                    Ideal for startups and solo enterpreneurs
                  </Typography>
                </Box>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography variant="h2">FREE</Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "50px",
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                ></Stack>
                <Typography>　</Typography>
                <div className="">
                  <ul className="features">
                    <li>New US or Canadian phone number</li>
                    <li>100% incoming calls are answered</li>
                    <li>Interactive natural language Attendant</li>
                    <li>
                      Calls transferred to any team member in the US or Canada
                      (limitations apply)
                    </li>
                    <li>
                      Add up to ten team members with their available hours to
                      take live calls
                    </li>
                    <li>
                      Get messages outside of available hours through email or
                      SMS
                    </li>
                  </ul>
                </div>
              </article>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, sm: 2 }}>
            <Stack
              direction="column"
              spacing={0}
              justifyContent="center"
              alignItems="stretch"
            >
              <article>
                <Typography variant="h3">Premium</Typography>

                <Box sx={{ minHeight: "55px" }}>
                  <Typography>Ideal for growing businesses</Typography>
                </Box>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography variant="h2" sx={{ width: "176px" }}>
                    US$20{monthYear === PRO_YEAR_PRICE_NAME ? "0" : ""}
                  </Typography>
                  <Stack
                    direction="column"
                    sx={{
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box>
                      <Typography>per</Typography>
                    </Box>
                    <Box>
                      <Typography>
                        {monthYear === PRO_YEAR_PRICE_NAME ? "Year" : "Month"}
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "50px",
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                >
                  <ToggleButtonGroup
                    color="primary"
                    value={monthYear}
                    exclusive
                    aria-label="Platform"
                    onChange={handleChange}
                  >
                    <ToggleButton value={PRO_MONTH_PRICE_NAME}>
                      Monthly
                    </ToggleButton>
                    <ToggleButton value={PRO_YEAR_PRICE_NAME}>
                      Yearly
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
                <Typography>Includes everything in the Starter plus</Typography>
                <div className="">
                  <ul className="features">
                    <li>Customise greeting and end call message </li>
                    <li>
                      Use Instruction Builder so Alice can answer callers’
                      questions{" "}
                    </li>
                    <li>Transfer your existing business number to Nucleus </li>
                    <li>Advanced voice models for more natural responses </li>
                  </ul>
                </div>
                <NucleusButton
                  variant="contained"
                  sx={{ width: "100%" }}
                  type="button"
                  onClick={() => onOpenStore(monthYear)}
                >
                  Continue with Premium
                </NucleusButton>
              </article>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  );
}
