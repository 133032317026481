import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { ErrorAlert } from "../components/alert/ErrorAlert";
import PlainContainer from "../components/container/PlainContainer";
import AuthContext from "../context/AuthContext";
import { HideIcon } from "../img/HideIcon";
import { LoadingCircle } from "../img/LoadingCircle";
import { RightArrow } from "../img/RightArrow";
import { ShowIcon } from "../img/ShowIcon";
import { useNavigateToTop } from "../utils/utils";
var owasp = require("owasp-password-strength-test");

interface IFormInput {
  password: string;
  password2: string;
}

const schema = yup
  .object({
    password: yup
      .string()
      .required("Password field is required.")
      .max(50)
      .test((value, ctx) => {
        var result = owasp.test(value);
        if (result.errors.length > 0) {
          return ctx.createError({
            message: result.errors[0],
          });
        }
        return true;
      }),
    password2: yup
      .string()
      .required("Password field is required.")
      .max(50)
      .oneOf([yup.ref("password")], "Password must match"),
  })
  .required();

export default function SigninView() {
  const navigate = useNavigate();
  const navigateToTop = useNavigateToTop();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("reset-token");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [password2Shown, setPassword2Shown] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: "",
      password2: "",
    },
  });

  const {
    actions: { resetPassword },
  } = useContext(AuthContext);

  if (!token) {
    navigateToTop("/");
    return;
  }

  const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
    const { password } = data;
    setError(null);
    setSuccess(false);
    setLoading(true);
    resetPassword(password, token)
      .then(() => {
        setError(null);
        setSuccess(true);

        navigateToTop("/");
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PlainContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="stretch"
        >
          {success && (
            <Alert
              severity="success"
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/signin")}
            >
              The password has been reset. <u>Click here</u> to sign in
            </Alert>
          )}
          <ErrorAlert error={error} />
          <Typography variant="h3">Reset Your Password</Typography>
          <Typography variant="body1">
            Strong passwords include numbers, letters, and symbols.
          </Typography>
          <Typography variant="h4">Password</Typography>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={errors.password !== undefined}
                helperText={errors.password ? errors.password?.message : "　"}
                className="rounded-md w-full !ring-transparent !outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5"
                type={passwordShown ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setPasswordShown(!passwordShown)}
                      >
                        {!passwordShown ? <ShowIcon /> : <HideIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  style: {
                    padding: "10px 12px",
                  },
                }}
              />
            )}
          />

          <Typography variant="h4">Confirm Password</Typography>
          <Controller
            name="password2"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={errors.password2 !== undefined}
                helperText={errors.password2 ? errors.password2?.message : "　"}
                className="rounded-md w-full !ring-transparent !outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5"
                type={password2Shown ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setPassword2Shown(!password2Shown)}
                      >
                        {!password2Shown ? <ShowIcon /> : <HideIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  style: {
                    padding: "10px 12px",
                  },
                }}
              />
            )}
          />

          <Button sx={{ width: "100%" }} variant="contained" type="submit">
            Reset Password {loading ? <LoadingCircle /> : <RightArrow />}
          </Button>
        </Stack>
      </form>
    </PlainContainer>
  );
}
