import { Alert } from "@mui/material";
import { useContext, useState } from "react";
import ApiContext from "../../context/ApiContext";
import AuthContext from "../../context/AuthContext";
import { LoadingCircle } from "../../img/LoadingCircle";
import { AccessLevel } from "../../interfaces/IGetUserResult";

interface VerifyEmailAlertProps {}

export default function VerifyEmailAlert(props: VerifyEmailAlertProps) {
  const [loading, setLoading] = useState(false);
  const {
    data: { account },
    actions: { resendVerifyEmail },
  } = useContext(AuthContext);

  const {
    actions: { setSuccess, setError },
  } = useContext(ApiContext);

  const handleEmailVerify = () => {
    setSuccess("");

    setLoading(true);
    const email = account?.username;
    if (email)
      resendVerifyEmail(email)
        .then(() => {
          setError(null);
          setSuccess(
            `Please check your inbox and click the verification link we just sent to ${email} to complete your registration`
          );
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
  };

  if (account?.access_level !== AccessLevel.GUEST && !account?.verified) {
    return (
      <>
        <Alert
          onClick={() => handleEmailVerify()}
          severity="info"
          sx={{ cursor: "pointer", svg: { display: "inline" } }}
        >
          Your free business phone and AI receptionist is almost ready to go!
          Click the link in your email to activate your starter account and
          get Alice working for you.
        </Alert>
      </>
    );
  }
}
