import { yupResolver } from "@hookform/resolvers/yup";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { isValidPhoneNumber } from "libphonenumber-js";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useBlocker, useNavigate } from "react-router-dom";
import { array, boolean, object, string } from "yup";
import { AIAgentSection } from "../components/section/AIAgentSection";
import { CompanySection } from "../components/section/CompanySection";
import { GreetingSection } from "../components/section/GreetingSection";
import { ModelCardSection } from "../components/section/ModelCardSection";
import { TeamAccordianSection } from "../components/section/TeamAccordianSection";
import ApiContext from "../context/ApiContext";
import AuthContext from "../context/AuthContext";
import { LoadingCircle } from "../img/LoadingCircle";
import { RightArrow } from "../img/RightArrow";
import { CommunicationType, Status } from "../interfaces/Agent";
import { AccessLevel, ICompany } from "../interfaces/IGetUserResult";
import { IHomeFormInput } from "../interfaces/IHomeFormInput";
import {
  default_goodbye,
  default_timezone,
  default_voice,
  default_welcome,
  MAX_CARD_LENGTH,
} from "../utils/consts";
import useFormPersist from "react-hook-form-persist";
import isBetween from "dayjs/plugin/isBetween";
import { useMediaMobile } from "../utils/utils";
import { AreYourThereDialog } from "../components/dialog/AreYourThereDialog";
import { NavBlockerDialog } from "../components/dialog/NavBlockerDialog";
import { STORAGE_HOME_FORM } from "../const/LocalStorageKeys";
import { CardItem } from "../interfaces/CardItem";
import { BannerStack } from "../components/BannerStack";
import { TitleToolTip } from "../components/TitleToolTip";
dayjs.extend(isBetween);

const availabilityItemSchema = {
  start: string().notRequired(),
  end: string()
    .notRequired()
    .test((end, ctx) => {
      const start = ctx.parent.start;
      if ((start && !end) || (!start && end))
        return ctx.createError({
          message: "Both a start and end time must be provided",
        });

      if (
        start &&
        end &&
        dayjs(start, "HH:mm ZZ").isAfter(dayjs(end, "HH:mm ZZ"))
      ) {
        return ctx.createError({
          message: "Start time must be before end time",
        });
      }
      return true;
    }),
  enabled: boolean().notRequired(),
};

const availabilitySchema = {
  monday: object(availabilityItemSchema).notRequired(),
  tuesday: object(availabilityItemSchema).notRequired(),
  wednesday: object(availabilityItemSchema).notRequired(),
  thursday: object(availabilityItemSchema).notRequired(),
  friday: object(availabilityItemSchema).notRequired(),
  saturday: object(availabilityItemSchema).notRequired(),
  sunday: object(availabilityItemSchema).notRequired(),
};

const agentSchema = {
  id: string().notRequired(),
  first_name: string().required("First name is required."),
  last_name: string().required("Last name is required."),
  phone: string()
    .required("Phone number is required.")
    .test((phone, ctx) => {
      if (!isValidPhoneNumber(phone, "CA"))
        return ctx.createError({
          message: `${phone} is not a valid phone number`,
        });
      return true;
    }),
  email: string()
    .notRequired()
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/, {
      message: "Please enter a valid email address.",
      excludeEmptyString: true,
    })
    .test((v, ctx) => {
      // eslint-disable-next-line eqeqeq
      if (!v && ctx.parent.prefered_communication == CommunicationType.EMAIL)
        return ctx.createError({
          message: "Email not provided",
        });

      return true;
    }),
  prefered_communication: string().oneOf([
    CommunicationType.EMAIL,
    CommunicationType.TEXT,
    CommunicationType.CALL,
  ]),
  status: string()
    .oneOf([Status.AVAILABLE, Status.SCHEDULE, Status.AWAY])
    .notRequired(),
  availability: object().shape(availabilitySchema).notRequired(),
};

const cardsSchema = {
  title: string().required("Model card missing title"),
  body: string()
    .required("Model card missing body")
    .max(
      MAX_CARD_LENGTH,
      `The total length of all instructions must not be longer than ${MAX_CARD_LENGTH} characters`
    ),
};

export const schema: any = object<IHomeFormInput>({
  company_name: string().required("Business name field is required."),
  voice: string().required("Voice field is required."),
  agents: array()
    .min(1, "Oops. Please add at least one user for your system.")
    .of(object().shape(agentSchema)),
  cards: array().of(object().shape(cardsSchema)),
  welcome_message: string().notRequired(),
  goodbye_message: string().notRequired(),
  time_zone: string().notRequired(),
});

const defaultFromCompany = (
  company: ICompany | null,
  defaultCards: CardItem[]
) => ({
  company_name: company?.name || "",
  voice: company?.voice || default_voice(),
  cards:
    company?.cards && company?.cards?.length >= 1
      ? company?.cards
      : defaultCards,
  agents: company?.users || [],
  welcome_message: company?.welcome_message || default_welcome(),
  goodbye_message: company?.goodbye_message || default_goodbye(),
  time_zone: company?.time_zone || default_timezone(),
});

export default function HomeView() {
  const navigate = useNavigate();
  const isMobile = useMediaMobile();

  const {
    data: { authToken, account, company },
    actions: { updateUser, expireAt, isLoggedIn },
  } = useContext(AuthContext);

  const {
    data: { defaultCards },
    actions: { setSuccess, setError },
  } = useContext(ApiContext);

  const generalAccordianRef = useRef<HTMLDivElement | null>();
  const teamAccordianRef = useRef<HTMLDivElement | null>();
  const greetingAccordianRef = useRef<HTMLDivElement | null>();

  const modelAccordianRef = useRef<HTMLDivElement | null>();
  const [modelAccordian, setModelAccordian] = useState(false);
  const [availableAccordian, setAvailableAccordian] = useState<boolean[]>([]);
  const [expandedIndex, setExpandedIndex] = useState<number>(-1);
  const [loading, setLoading] = useState(false);

  const [isAreYouOpen, setIsAreYouOpen] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;
    timeout = setInterval(() => {
      if (!isLoggedIn()) setIsAreYouOpen(true);
    }, 60 * 1000);
    //}
    return () => {
      clearTimeout(timeout);
    };
  }, [authToken, expireAt, account, isLoggedIn, navigate]);

  const [loadingLock, setLock] = useState(true);

  const defaultValues: IHomeFormInput = useMemo<IHomeFormInput>(
    () => defaultFromCompany(company, defaultCards),
    []
  );

  const [canFocus, setCanFocus] = useState(true);
  const methods = useForm<IHomeFormInput>({
    shouldFocusError: false,
    resolver: yupResolver<IHomeFormInput>(schema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { isDirty, errors },
  } = methods;

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isLoggedIn() &&
      isDirty &&
      currentLocation.pathname !== nextLocation.pathname
  );

  const formPersist = useFormPersist(STORAGE_HOME_FORM, {
    watch: !loadingLock ? watch : () => {},
    setValue,
    dirty: true,
  });

  useEffect(() => {
    setTimeout(() => {
      setLock(false);
    }, 1);
  }, []);

  const onError = () => {
    setCanFocus(true);
  };

  useEffect(() => {
    if (methods.formState.errors && canFocus) {
      const keyify = (obj: any, prefix = ""): string[] =>
        Object.keys(obj).reduce((res: string[], el: string) => {
          if (typeof obj[el] === "object" && obj[el] !== null) {
            return [...res, ...keyify(obj[el], prefix + el + ".")];
          }
          return [...res, prefix + el];
        }, []);

      // Sort inputs based on their position on the page. (the order will be based on validaton order otherwise)
      const errors = methods.formState.errors;
      const keys = keyify(errors);
      const filterKeys = keys
        .filter((key) => key.endsWith(".message"))
        .map((key) => key.slice(0, -1 * ".message".length));

      const elements = filterKeys
        .map((name) => document.getElementsByName(name)[0])
        .filter((el) => !!el);
      elements.sort(
        (a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top
      );

      if (elements.length > 0) {
        let errorElement = elements[0];
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" }); // scrollIntoView options are not supported in Safari
        errorElement.focus({ preventScroll: true });
        setCanFocus(false); // so the form doesn't suddenly jump to the next input that has error.
      }
    }
  }, [methods.formState, canFocus]);

  const setErrorAndScroll = (err: any) => {
    setError(err);
    setTimeout(() => {
      document.getElementById("errorAlert")?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 250);
  };

  const formatPhoneNumber = (phoneNumber: string) =>
    phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");

  const onSubmit: SubmitHandler<IHomeFormInput> = (data: IHomeFormInput) => {
    const {
      company_name,
      voice,
      cards,
      agents,
      welcome_message,
      goodbye_message,
      time_zone,
    } = data;

    setSuccess("");

    setLoading(true);

    updateUser(
      company_name,
      voice,
      cards,
      agents,
      welcome_message,
      goodbye_message,
      time_zone
    )
      .then((result) => {
        const exp = new Array(agents.length).fill(false);
        setAvailableAccordian(exp);
        setModelAccordian(false);
        setExpandedIndex(-1);
        setError(null);
        setSuccess(
          "Success! Your changes have been immediately saved and are now live"
        );

        const defaultValues = defaultFromCompany(result.company, defaultCards);
        reset(defaultValues);
      })
      .catch((error) => {
        setErrorAndScroll(error);
      })
      .finally(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });

        setLoading(false);
      });
  };

  const onAvailabilityChange = (
    index: number,
    expand: boolean,
    e?: React.SyntheticEvent
  ) => {
    e?.stopPropagation();
    const exp = [...availableAccordian];
    while (exp.length < index) {
      exp.push(false);
    }
    exp[index] = expand;
    setAvailableAccordian(exp);
  };

  const disabled = account?.access_level === AccessLevel.SUSPENDED;

  if (loadingLock)
    return (
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress size={50} />
      </Box>
    );

  return (
    <Paper className="p-4" elevation={1} sx={{ width: "100%" }}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Stack
            direction="column"
            sx={{ maxWidth: 1280, margin: "auto", minHeight: "80vh" }}
            spacing={3}
          >
            <BannerStack />

            <AreYourThereDialog
              open={isAreYouOpen}
              onClose={() => {
                setIsAreYouOpen(false);
              }}
            />

            <NavBlockerDialog
              open={blocker.state === "blocked"}
              onClose={() => {}}
              onOk={() => {
                if (blocker && blocker.proceed) blocker.proceed();
              }}
              onCancel={() => {
                if (blocker && blocker.reset) blocker.reset();
              }}
            />
            <Box>
              <Typography variant="h3">
                Your number: {company && formatPhoneNumber(company.did)}
              </Typography>
            </Box>
            <Box ref={generalAccordianRef}>
              <Stack
                direction="row"
                spacing={0}
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}
              >
                <Typography variant="h4" sx={{ marginBottom: 1 }}>
                  General
                </Typography>
                <TitleToolTip
                  title="Customize your business details so Alice can answer and transfer your calls correctly."
                  yt_link="https://youtu.be/I0uALlw5FbY?si=hfPlmr4g9mVXzVoy"
                  yt_title="The General Widget - YouTube"
                />
              </Stack>

              <Box>
                <CompanySection disabled={disabled} />
              </Box>
            </Box>
            <Box>
              <Stack
                direction="row"
                spacing={0}
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}
              >
                <Typography variant="h4" sx={{ marginBottom: 1 }}>
                  Agent
                </Typography>
                <TitleToolTip
                  title="Customize the voice of your AI Agent."
                  yt_link="https://youtu.be/8REWKNwq6XM?si=yt9xh10yVVWZC5OE"
                  yt_title="The Agent Widget - YouTube"
                />
              </Stack>
              <AIAgentSection disabled={disabled} />
            </Box>
            <Box ref={greetingAccordianRef}>
              <Stack
                direction="row"
                spacing={0}
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}
              >
                <Typography variant="h4" sx={{ marginBottom: 1 }}>
                  Greetings
                </Typography>
                <TitleToolTip
                  titleElement={
                    <Typography>
                      Customize how Alice greets your callers.{" "}
                      <b>*Premium feature</b>
                    </Typography>
                  }
                  yt_link="https://youtu.be/pTCJI4sWwSs?si=glVll6NEkigrbloJ"
                  yt_title="The Greeting Widget - YouTube"
                />
              </Stack>
              <GreetingSection
                disabled={disabled}
                setIsRegOpen={(open) => {
                  if (open) navigate("/store");
                }}
              />
            </Box>

            <Box ref={teamAccordianRef}>
              <Stack
                direction="row"
                spacing={0}
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}
              >
                <Typography variant="h4" sx={{ marginBottom: 1 }}>
                  Team Members
                </Typography>
                <TitleToolTip
                  title="Create your Nucleus company directory so Alice can transfer calls and take messages."
                  yt_link="https://youtu.be/IWFi9elCvL8?si=d-Pmd3BuY4zZnM7n"
                  yt_title="The Team Member Widget - YouTube"
                />
              </Stack>
              <TeamAccordianSection
                disabled={disabled}
                expanded={availableAccordian}
                expandedIndex={expandedIndex}
                setExpandedIndex={setExpandedIndex}
                onChange={onAvailabilityChange}
              />
            </Box>

            <Stack direction="column" spacing={0}>
              <Accordion
                slotProps={{ transition: { unmountOnExit: true } }}
                disableGutters
                elevation={0}
                square
                disabled={disabled}
                expanded={modelAccordian || errors.cards !== undefined}
                onChange={(e, isExpanded) => {
                  setModelAccordian(isExpanded);
                  if (isExpanded)
                    setTimeout(() => {
                      modelAccordianRef.current?.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                      });
                    }, 500);
                }}
              >
                <AccordionSummary
                  aria-controls="panel2-content"
                  id="panel2-header"
                  expandIcon={
                    <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />
                  }
                >
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography variant="h4" sx={{ margin: 0 }}>
                      Instruction Builder
                    </Typography>

                    <TitleToolTip
                      titleElement={
                        <Typography>
                          Teach Alice about your business so that she can answer
                          your callers’ questions. <b>*Premium feature</b>
                        </Typography>
                      }
                      yt_link="https://youtu.be/sZ71qPD9bHA?si=dDAAcUS_orVjl4RE"
                      yt_title="The Instruction Builder - YouTube"
                    />
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  {account?.access_level !== AccessLevel.PRO &&
                    process.env.REACT_APP_STRIPE_ENABLE === "true" && (
                      <Alert
                        onClick={() =>
                          navigate(
                            account?.access_level === AccessLevel.FREE
                              ? "/upgrade"
                              : "/store"
                          )
                        }
                        severity="warning"
                        sx={{ cursor: "pointer", margin: 2 }}
                      >
                        This feature is limited to Premium accounts.{" "}
                        <u>Click here</u> to unlock.
                      </Alert>
                    )}
                  {account?.access_level === AccessLevel.GUEST &&
                    process.env.REACT_APP_STRIPE_ENABLE !== "true" && (
                      <Alert
                        onClick={() => navigate("/store")}
                        severity="warning"
                        sx={{ cursor: "pointer", margin: 2 }}
                      >
                        This feature is limited. <u>Click here</u> to unlock.
                      </Alert>
                    )}

                  <Box ref={modelAccordianRef} sx={{ width: "100%" }}>
                    <ModelCardSection />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Stack>
            <Grid
              container
              item
              xs={12}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 1,
                marginBottom: 1,
              }}
            >
              <Grid item xs={12} md={3}></Grid>
              {!isMobile && <Grid item xs={0} md={4}></Grid>}
              <Grid item xs={12} md={3} sx={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ width: { xs: "100%", md: 200 } }}
                  disabled={disabled}
                >
                  Submit
                  {loading ? (
                    <LoadingCircle />
                  ) : (
                    <div className="flex items-center justify-center w-3 ml-1">
                      <RightArrow />
                    </div>
                  )}
                </Button>
              </Grid>
            </Grid>

            <Stack
              direction="row-reverse"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={2}
            ></Stack>
          </Stack>
        </form>
      </FormProvider>
    </Paper>
  );
}
