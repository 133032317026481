import { Box, CircularProgress } from "@mui/material";
import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { useNavigateToTop } from "../utils/utils";

const properties = {
  display: "grid",
  placeItems: "center",
  height: "100vh",
};

export default function VerifyView() {
  const navigateToTop = useNavigateToTop();
  const [searchParams] = useSearchParams();

  const {
    actions: { loginToken },
  } = useContext(AuthContext);

  useEffect(() => {
    const checkQuery = async () => {
      const query = searchParams.get("token");
      if (query)
        try {
          await loginToken(query);
        } catch (e) {}
      navigateToTop("/");
    };
    checkQuery();
  }, []);

  return (
    <Box sx={properties}>
      <CircularProgress size={50} />
    </Box>
  );
}
