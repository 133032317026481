import { Alert } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { AccessLevel } from "../../interfaces/IGetUserResult";

interface FreeUpsellAlertProps {}

export default function FreeUpsellAlert(props: FreeUpsellAlertProps) {
  const navigate = useNavigate();

  const {
    data: { account },
  } = useContext(AuthContext);

  if (
    account?.access_level === AccessLevel.FREE &&
    process.env.REACT_APP_STRIPE_ENABLE === "true" &&
    account.verified
  )
    return (
      <Alert
        onClick={() => navigate("/upgrade")}
        severity="info"
        sx={{ cursor: "pointer" }}
      >
        Upgrade your free account to Premium. <u>Click here</u> to check out all
        the great benefits of Nucleus Premium.
      </Alert>
    );
}
