import { Outlet } from "react-router-dom";
import CrispChat from "../components/crisp/crisp";

export default function CrispLayout() {
  return (
    <>
      <Outlet />
      <CrispChat />
    </>
  );
}
