import { ThemeProvider } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { ReactElement, useContext } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";
import "./App.css";
import { ApiContextProvider } from "./context/ApiContext";
import AuthContext, { AuthContextProvider } from "./context/AuthContext";
import { TrackingContextProvider } from "./context/TrackingContext";
import AppLayout from "./layouts/AppLayout";
import AuthLayout from "./layouts/AuthLayout";
import { innerTheme, theme } from "./theme";
import PublicRoutes from "./utils/PublicRoutes";
import ForgotPasswordView from "./views/ForgotPasswordView";
import HomeView from "./views/HomeView";
import RegistrationEmbed from "./views/RegistrationEmbed";
import RegisterView from "./views/RegistrationView";
import ResetPasswordView from "./views/ResetPasswordView";
import SigninView from "./views/SigninView";
import SignupView from "./views/SignupView";
import StoreCartView from "./views/StoreCartView";
import StorePortalView from "./views/StorePortalView";
import StoreView from "./views/StoreView";
import EmailVerifyView from "./views/VerifyEmailView";

import { SocketContextProvider } from "./context/SocketContext";
import CrispLayout from "./layouts/CrispLayout";
import NotFoundView from "./views/NotFoundView";
import VerifyView from "./views/VerifyView";
import { BannerContextProvider } from "./context/BannerContext";

interface ViewWrapperProps {
  element: ReactElement;
  redirect?: boolean;
}
function RedirectPage(): ReactElement {
  React.useEffect(() => {
    window.location.replace(process.env.REACT_APP_WEB_URL || "/");
  }, []);
  return <></>;
}

function ViewWrapper(props: ViewWrapperProps): ReactElement {
  const { element } = props;
  const {
    actions: { isLoggedIn },
  } = useContext(AuthContext);

  if (isLoggedIn())
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {element}
      </LocalizationProvider>
    );
  return <RedirectPage />;
}

const WrapperComponent = () => {
  return (
    <AuthContextProvider>
      <ApiContextProvider>
        <SocketContextProvider>
          <TrackingContextProvider>
            <BannerContextProvider>
              <Outlet />
              <ScrollRestoration />
            </BannerContextProvider>
          </TrackingContextProvider>
        </SocketContextProvider>
      </ApiContextProvider>
    </AuthContextProvider>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<WrapperComponent />}>
      <Route element={<CrispLayout />}>
        <Route element={<AppLayout />}>
          <Route path="*" element={<NotFoundView />} />
          <Route
            path="/"
            element={<ViewWrapper redirect={true} element={<HomeView />} />}
          />
          <Route path="/signup" element={<SignupView />} />
          <Route
            path="/billing"
            element={
              <ViewWrapper redirect={true} element={<StorePortalView />} />
            }
          />
          <Route
            path="/upgrade"
            element={
              <ViewWrapper redirect={true} element={<StoreCartView />} />
            }
          />
        </Route>
        <Route
          path="/complete"
          element={
            <ViewWrapper
              redirect
              element={<RegisterView showStore={false} />}
            />
          }
        />

        <Route path="/register" element={<RegisterView showStore />} />

        <Route element={<PublicRoutes />}>
          <Route element={<AuthLayout />}>
            <Route path="/signin" element={<SigninView />} />
            <Route path="/forgot-password" element={<ForgotPasswordView />} />
          </Route>
        </Route>
        <Route path="/reset-password" element={<ResetPasswordView />} />
        <Route path="/verify" element={<EmailVerifyView />} />
        <Route path="/check" element={<VerifyView />} />
        <Route path="/store" element={<StoreView />} />
      </Route>
      <Route path="/reg" element={<RegistrationEmbed />} />
    </Route>
  )
);

function App() {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <ThemeProvider theme={innerTheme}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;
