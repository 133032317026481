import React, { useContext, useEffect } from "react";
import { io } from "socket.io-client";
import AuthContext from "./AuthContext";

const ACCOUNT_UPDATE = "account:update";
const STRIPE_UPDATE = "stripe:update";

const SocketContext = React.createContext<{
  actions: {};
  data: {};
}>({
  actions: {},
  data: {},
});

interface SocketContextProviderProps {
  children: React.ReactNode;
}
const socket = io(process.env.REACT_APP_WS_URL, {
  path: "/api/v1/socket.io",
  transports: ["websocket"],
});

export function SocketContextProvider(
  props: SocketContextProviderProps
): React.ReactElement {
  const { children } = props;

  const {
    data: { authToken },
    actions: { isLoggedIn, syncStripe, getUser },
  } = useContext(AuthContext);

  useEffect(() => {
    if (isLoggedIn()) {
      socket.on("connect", () => {
        socket.emit("join", { token: authToken?.access_token });
        syncStripe();
        getUser();
      });
      socket.on(STRIPE_UPDATE, (data) => {
        getUser();
      });
      socket.on(ACCOUNT_UPDATE, (data) => {
        getUser();
      });

      socket.connect();

      if (socket.connected)
        socket.emit("join", { token: authToken?.access_token });

      return () => {
        socket.removeAllListeners();
        socket.close();
      };
    }
  }, [authToken, getUser, isLoggedIn, syncStripe]);

  return (
    <SocketContext.Provider
      value={{
        actions: {},
        data: {},
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}
export default SocketContext;
