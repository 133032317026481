export const Premium = (props: {
  color?: string;
  size?: number;
  strokeWidth?: number;
}) => {
  return (
    <svg
      width="24"
      height="24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.987"
        y="4.0162"
        width="15.864"
        height="16.411"
        fill="#fff"
        stroke-width="0"
      />
      <path
        d="m0.042106 12.042c0-6.6274 5.3726-12 12-12 6.6274 0 12 5.3726 12 12 0 6.6274-5.3726 12-12 12-6.6274 0-12-5.3726-12-12zm12-6.4c0.31018 0 0.5924 0.1793 0.72419 0.46008l1.5492 3.3005 3.4474 0.52637c0.29632 0.045248 0.54256 0.25248 0.63776 0.53683s0.02352 0.59806-0.18592 0.81269l-2.518 2.5807 0.59613 3.654c0.04928 0.3024-0.0784 0.6064-0.32878 0.78272-0.25045 0.17648-0.57966 0.1944-0.84779 0.04624l-3.0741-1.6994-3.0741 1.6994c-0.26811 0.14816-0.59734 0.13024-0.84779-0.04624-0.25045-0.17632-0.37813-0.48032-0.3288-0.78272l0.59614-3.654-2.518-2.5807c-0.20941-0.21462-0.28122-0.52834-0.18602-0.81269 0.095216-0.28435 0.34144-0.49158 0.63786-0.53683l3.4474-0.52637 1.5492-3.3005c0.13179-0.28078 0.41402-0.46008 0.72419-0.46008z"
        clipRule="evenodd"
        fill="#5e6cb2"
        fillRule="evenodd"
        stroke-width="1.6"
      />
    </svg>
  );
};
