import { Alert } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { AccessLevel } from "../../interfaces/IGetUserResult";
import { theme } from "../../theme";
import { RegisterDialog } from "../dialog/RegisterDialog";
import ApiContext from "../../context/ApiContext";

interface GuestAlertProps {}

export default function GuestAlert(props: GuestAlertProps) {
  const navigate = useNavigate();
  const [isRegOpen, setIsRegOpen] = useState(false);
  const [guestTime, setGuestTime] = useState(0);

  const {
    data: { authToken, account },
    actions: { expireAt, isLoggedIn },
  } = useContext(AuthContext);

  const {
    actions: { setSuccess },
  } = useContext(ApiContext);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;
    //if (isGuest) {
    const diff = expireAt(authToken) - Date.now();
    const totalMinutes = Math.max(0, Math.floor(diff / (60 * 1000)));
    setGuestTime(totalMinutes);

    timeout = setTimeout(() => {
      const diff = expireAt(authToken) - Date.now();
      const totalMinutes = Math.max(0, Math.floor(diff / (60 * 1000)));
      setGuestTime(totalMinutes);
    }, 60 * 1000);
    //}
    return () => {
      clearTimeout(timeout);
    };
  }, [authToken, expireAt, account, isLoggedIn, navigate]);

  if (account?.access_level === AccessLevel.GUEST)
    return (
      <>
        <RegisterDialog
          open={isRegOpen}
          onClose={(reason) => {
            if (reason) {
              setSuccess(reason);
            }
            setIsRegOpen(false);
          }}
        />
        <Alert
          onClick={() => navigate("/store")}
          severity="success"
          sx={{
            cursor: "pointer",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
          }}
        >
          {guestTime > 0
            ? `Success! You have launched your ${guestTime} minute demo. Call the number below to talk to your business.`
            : "Success! Your demo has expired."}
          To fully activate your account, <u>click here</u>
        </Alert>
      </>
    );
}
