import {
  Box,
  IconButton,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import { Youtube } from "../img/Youtube";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

interface TitleToolTipProps {
  title?: string;
  titleElement?: React.ReactElement;
  yt_link: string;
  yt_title: string;
}

export function TitleToolTip(props: TitleToolTipProps) {
  const { title, titleElement, yt_link, yt_title } = props;

  return (
    <HtmlTooltip
      title={
        <Box>
          {title && <Typography>{title}</Typography>}
          {titleElement}
          <a href={yt_link} target="_blank" rel="noopener noreferrer">
            <Stack
              direction="row"
              spacing={0}
              sx={{
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Youtube sx={{ width: "20px", margin: 1 }} />
              <Typography>
                <u>{yt_title}</u>
              </Typography>
            </Stack>
          </a>
        </Box>
      }
    >
      <IconButton>
        <Info />
      </IconButton>
    </HtmlTooltip>
  );
}
