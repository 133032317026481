import { Box, CircularProgress } from "@mui/material";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import { useContext } from "react";
import ApiContext, { PRO_YEAR_PRICE_NAME } from "../context/ApiContext";
import AuthContext from "../context/AuthContext";
import { useSearchParams } from "react-router-dom";
const apiKey = process.env.REACT_APP_STRIPE_API_KEY;

const properties = {
  display: "grid",
  placeItems: "center",
  height: "100vh",
};
export default function StoreView() {
  const [searchParams] = useSearchParams();

  const {
    data: { account },
    actions: { isLoggedIn },
  } = useContext(AuthContext);

  const {
    actions: { createStoreSession },
  } = useContext(ApiContext);

  if (!isLoggedIn())
    return (
      <Box sx={properties}>
        <CircularProgress size={50} />
      </Box>
    );

  const email = account?.username;

  const fetchClientSecret = (): Promise<string> => {
    const promo_code = searchParams.get("promo_code") || undefined;
    const return_url =
      !isLoggedIn() && email && email.length > 0
        ? `${window.location.origin}/complete`
        : `${window.location.origin}/?sessionid={CHECKOUT_SESSION_ID}`;
    const lookup_key = searchParams.get("lookup_key") || PRO_YEAR_PRICE_NAME;
    return createStoreSession(promo_code, return_url, lookup_key);
  };
  const loadStripePromise = async (): Promise<Stripe | null> => {
    if (apiKey) {
      const stripePromise = await loadStripe(apiKey);
      return stripePromise;
    } else {
      console.log("MISSING STRIPE_API_KEY");
      return null;
    }
  };
  const options = { fetchClientSecret };
  return (
    <EmbeddedCheckoutProvider stripe={loadStripePromise()} options={options}>
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  );
}
